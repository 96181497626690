var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "success-box"
  }, [_vm._m(0), _c('div', {
    staticClass: "state"
  }, [_vm._v("领取成功")]), _c('div', {
    staticClass: "tip"
  }, [_vm._v("我们将在5个工作日内发货，届时请留意您预留的手机号码")]), _c('div', {
    staticClass: "button",
    on: {
      "click": function ($event) {
        return _vm.$router.replace('/shop');
      }
    }
  }, [_vm._v("完成")])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-4.png"
    }
  })]);
}];
export { render, staticRenderFns };